<template>
  <!-- 边缘分析盒 -->
  <div class="box-card" style="margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="场景：">
        <el-cascader popper-class="my-cascader" filterable v-model="queryForm.category_id_list"
          :options="mainFunAccCodeList" clearable :props="defaultParams" @change="handleChange" style="width:100%"
          placeholder="请选择场景">
          <template slot-scope="{ data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="设备名称：">
        <el-input v-model="queryForm.aibox_name" maxlength="30" @keyup.enter.native="onSubmit"
          @input="(e) => (queryForm.aibox_name = validSpace(e))" placeholder="请输入设备名称"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select popper-class="my-select" placeholder="请选择设备状态"  v-model="queryForm.aibox_state"
            @change="onSubmit" @clear="queryForm.aibox_state = 0">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="在线" :value="1"></el-option>
            <el-option label="离线" :value="2"></el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="边缘分析盒">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('6392b46447530000b8005d8c')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="isAddDialog = true" v-if="!isAdmin && isShowBtn('6392b46447530000b8005d8a')">新增
          </lbButton>
        </div>
      </template>
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
           <template slot-scope="{row}" slot="aibox_state">
            <div :style="!row.aibox_state ? 'color: #ff0000;' : 'color: #18943b;'">
                {{ !row.aibox_state ? "离线" : "在线" }}
              </div>
            </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="fnEdit(row)"
                v-if="isShowBtn('6392b46447530000b8005d8b')"></lbButton>
              <lbButton size="info" icon="shebeipeizhi" hint="通道" @click="getChannel(row, true)"
                v-if="isShowBtn('6392b46447530000b8005d8b')">
              </lbButton>
            </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="aibox_sn" label="序列号"></el-table-column>
          <el-table-column prop="aibox_name" label="设备名称"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <div :style="!scope.row.aibox_state ? 'color: #ff0000;' : 'color: #18943b;'">
                {{ !scope.row.aibox_state ? "离线" : "在线" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="fnEdit(scope.row)"
                v-if="isShowBtn('6392b46447530000b8005d8b')"></lbButton>
              <lbButton size="info" icon="shebeipeizhi" hint="通道" @click="getChannel(scope.row, true)"
                v-if="isShowBtn('6392b46447530000b8005d8b')">
              </lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 新增编辑 -->
    <AddEdgeAnalysis :editForm="editForm" @close="closeAddDialog" v-if="isAddDialog"></AddEdgeAnalysis>
    <!-- 配置 -->
    <!-- <ConfigEdgeAnalysis v-if="isConfigDialog" @close="isConfigDialog = false"></ConfigEdgeAnalysis> -->
    <!-- 查看设备 -->
    <!-- <el-dialog :close-on-click-modal="false" append-to-body title="视频监控设备" :visible.sync="isLookDialog" width="30%"  class="table_box">
      <el-table :data="deviceDataList" border stripe>
        <el-table-column header-align="center" align="center" type="index" label="序号" width="100"></el-table-column>
        <el-table-column header-align="center" align="center" prop="ChannelName" label="设备名称"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isLookDialog = false">关 闭</el-button>
      </span>
    </el-dialog> -->
    <!-- 编辑通道信息 -->
    <lebo-dialog append-to-body title="视频监控通道配置" :isShow="showChannelDetail" @close="showChannelDetail = false" footerSlot
      width="35%">
      <el-form class="channelDetail">
        <el-form-item label="" prop="">
          <div v-for="(item, index) in channelList" :key="index" class="work_shift_box">
            <span>通道{{ index + 1 + ' ' }}:</span>
            <el-form-item class="workShiftInput">
              <el-input v-model="item.name" maxlength="30" @keyup.enter.native="onSubmit" placeholder="请输入通道名称"
                @input="(e) => (item.name = divideSymbolDot(e))"></el-input>
            </el-form-item>
            <el-form-item class="workShiftInput">
              <el-input v-model="item.token" maxlength="30" @keyup.enter.native="onSubmit" placeholder="请输入通道token"
                @input="(e) => (item.token = divideSymbolDot(e))"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-plus" v-if="index === 0" size="mini" circle
                @click="addBillingTime(index)"></el-button>
              <el-button icon="el-icon-minus" v-if="index > 0" size="mini" circle
                @click="delBillingTime(index)"></el-button>
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="showChannelDetail = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="setChannel" v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getAiBoxDevicePageList, delAiBoxDevice, getEquipmentList, changeChannelDetail, getBindScene } from '@/api/monitoring'
import AddEdgeAnalysis from './components/addEdgeAnalysis/addEdgeAnalysis.vue'
export default {
  components: {
    AddEdgeAnalysis
    // ConfigEdgeAnalysis,
  },
  data () {
    return {
      queryForm: {
        PageIndex: 1,
        PageSize: 10,
        aibox_sn: '',
        aibox_name: '',
        group_id: '',
        category_id_list: [],
        aibox_state: 0 // 设备是否在线 1 在线 2 离线 0全部
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      isAddDialog: false,
      editForm: null,
      isConfigDialog: false,
      isLookDialog: false,
      deviceDataList: [], // 监控设备列表
      showChannelDetail: false, // 通道信息弹框
      channelList: [], // 通道数组
      // 场景列表
      mainFunAccCodeList: [],
      channelDetail: { alarm_chn: '', token: '', name: '' }, // 通道信息
      boxId: '', // 分析盒子ID
      defaultParams: {
        label: 'name',
        value: 'Id',
        children: 'children'
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '序列号',
            prop: 'aibox_sn'
          }, {
            label: '设备名称',
            prop: 'aibox_name'
          }, {
            label: '状态',
            prop: 'aibox_state',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetAiBoxDevicePageList()
    this.fngetBindScene()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    handleChange (value) {
      this.queryForm.category_id_list = value
      this.onSubmit()
    },
    // 获取场景列表
    async fngetBindScene () {
      const res = await getBindScene()
      this.mainFunAccCodeList = this.filterChildren(res.Data)
    },
    // 获取设备列表
    async fnGetAiBoxDevicePageList () {
      const res = await getAiBoxDevicePageList(this.queryForm)
      console.log('获取设备列表', res)
      this.tableData = res && res.Code === 200 ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAiBoxDevicePageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      this.queryForm.PageSize = val
      this.fnGetAiBoxDevicePageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetAiBoxDevicePageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetAiBoxDevicePageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetAiBoxDevicePageList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的边缘分析盒, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var arr = []
          this.multipleSelection.forEach(item => arr.push(item.Id))
          this.fndelAiBoxDevice({ ids: arr })
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的边缘分析盒！')
      }
    },
    async fndelAiBoxDevice (obj) {
      const res = await delAiBoxDevice(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetAiBoxDevicePageList()
    },
    // 关闭新增对话框时触发
    closeAddDialog (flag) {
      console.log(flag)
      this.isAddDialog = false
      this.editForm = null
      if (flag) {
        this.onSubmit()
      } else {
        this.fnGetAiBoxDevicePageList()
      }
    },
    // 点击编辑按钮
    fnEdit (item) {
      console.log('scop', item)
      this.editForm = {
        Id: item.Id,
        aibox_sn: item.aibox_sn,
        aibox_name: item.aibox_name,
        scene_id: item.scene_id, // 场景id
        category_id: item.category_id,
        overall_token: item.overall_token
      }
      this.isAddDialog = true
    },
    // 查看监控设备
    async openParking (item) {
      const res = await getEquipmentList({ SceneId: item.scene_id })
      // console.log('监控！', item)
      if (res.Code === 200 && res.Data && res.Data.length > 0) {
        this.deviceDataList = res.Data
      } else {
        this.deviceDataList = []
      }
      this.isLookDialog = true
    },
    // 查看编辑通道信息
    getChannel (item, boolen) {
      console.log(item, boolen)
      this.boxId = item.Id
      let jsonstring = ''
      if (item.aibox_tokens) {
        // eslint-disable-next-line no-eval
        jsonstring = eval(item.aibox_tokens)
        if (jsonstring.length > 0) {
          this.channelList = jsonstring
        } else {
          this.channelList = [{ alarm_chn: '', token: '', name: '' }]
        }
      } else {
        this.channelList = [{ alarm_chn: '', token: '', name: '' }]
      }
      this.showChannelDetail = true
    },
    // 确定修改通道信息
    setChannel () {
      // 剔除未命名的通道
      this.showChannelDetail = false

      this.channelList.forEach((item, index) => {
        if (item.name === '' && item.token === '') {
          this.channelList.splice(index, 1)
        }
      })
      this.channelList.forEach((item, index) => {
        item.alarm_chn = index
      })
      const sendData = {
        Id: this.boxId,
        aibox_tokens: this.channelList
      }
      this.fnchangeChannelDetail(sendData)
    },
    // 更改通道信息
    async fnchangeChannelDetail (data) {
      // console.log('更改通道信息', data)
      const res = await changeChannelDetail(data)
      if (res.Code === 200) {
        this.fnGetAiBoxDevicePageList()
      }
    },
    // 增加按钮
    addBillingTime () {
      this.channelList.push({ alarm_chn: '', token: '', name: '' })
    },
    // 删除按钮
    delBillingTime (index) {
      this.channelList.splice(index, 1)
    }
  }
}
</script>
<style scoped lang="less">
.table_box {
  /deep/ .el-table__body-wrapper {
    max-height: 431px;
    overflow-y: auto;
  }
}

.channelDetail {
  max-height: 600px;
  overflow: auto;
}

.work_shift_box {
  width: 100%;
  box-sizing: border-box;
  height: 65px;
  display: flex;

  span {
    min-width: 55px;
  }

  .workShiftInput {
    width: 40%;
    margin-right: 10px;

    .el-input {
      text-align: left;
      width: 100%;
    }
  }

  .el-button {
    margin-left: 10px;
    padding: 8px !important;
  }

  // .el-form-item {
  //   margin-left: 10px;
  //   text-align: left;
  //   margin-right: 10px !important;
  //   .el-input {
  //   margin-left: 10px;
  //   text-align: left;
  //     width: 350px;
  //   }
  //   .el-button {
  //     margin-left: 10px;
  //     padding: 8px !important;
  //   }
  //   &:nth-child(1) {
  //     margin-right: 80px;
  //   }
  // }
}
</style>
