<template>
  <!-- 新增边缘分析盒 -->
  <lebo-dialog  append-to-body :title="Id ? '编辑设备': '新增设备'" :isShow="isAddDialog" width="30%" @close="closeAddDialogVisible()" footerSlot>
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="序列号：" prop="aibox_sn">
        <el-input v-model="addForm.aibox_sn" maxlength="30" placeholder="请输入序列号" @input="(e) => (addForm.aibox_sn = divideSymbol(e))" :disabled="Id !== ''"></el-input>
      </el-form-item>
      <el-form-item label="设备名称：" prop="aibox_name">
        <el-input v-model="addForm.aibox_name" placeholder="请输入设备名称" maxlength="30" @input="(e) => (addForm.aibox_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="所属场景：" prop="scene_id">
        <el-cascader popper-class="my-cascader" filterable v-model="addForm.scene_id" :options="sceneSelectList" clearable :props="defaultParams" @change="handleChange" style="width:100%" placeholder="请选择场景" key="addForm.scene_id">
          <template slot-scope="{ data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="设备token：">
        <el-input v-model="addForm.overall_token" placeholder="请输入设备token" maxlength="30" @input="(e) => (addForm.overall_token = validFacility(e))"></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- <lbButton type="goBack" icon="back" @click="isAddDialog = false">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
    </span>
  </lebo-dialog>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { addAiBoxDevice, updateAiBoxDevice, getBindScene } from '@/api/monitoring'
export default {
  props: {
    editForm: { type: Object }
  },
  data () {
    return {
      isAddDialog: true,
      Id: '',
      addForm: {
        aibox_sn: '', // 设备序列号
        aibox_name: '', // 设备名称
        scene_id: [], // 场景ID
        category_id: '', // 下级架构ID
        overall_token: '' // 设备token
      },
      addFormRules: {
        aibox_sn: [{ required: true, message: '请输入设备序列号', trigger: 'blur' }],
        aibox_name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        scene_id: [
          { required: true, message: '请选择场景', trigger: 'blur' }
        ]
      },
      sceneSelectList: [], // 场景下拉列表
      defaultParams: {
        label: 'name',
        value: 'Id',
        children: 'children'
      },
      changecategoryId: '',
      changesceneId: ''
    }
  },
  created () {

  },
  mounted () {
    // 获取场景下拉列表
    this.fnGetSceneSelectList()
    if (this.editForm) {
      this.Id = this.editForm.Id
      this.addForm.aibox_sn = this.editForm.aibox_sn
      this.addForm.aibox_name = this.editForm.aibox_name
      this.addForm.scene_id = this.editForm.category_id !== this.editForm.scene_id ? [this.editForm.scene_id, this.editForm.category_id] : [this.editForm.scene_id]// 场景id 回显
      this.addForm.category_id = this.editForm.category_id // 架构id
      this.addForm.overall_token = this.editForm.overall_token
    }
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    // 关闭对话框时触发
    closeAddDialogVisible (flag) {
      this.isAddDialog = false
      this.$emit('close', flag)
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          if (this.Id) {
            this.fnUpdateAiBoxDevice(this.addForm)
          } else {
            this.fnAddAiBoxDevice(this.addForm)
          }
        } else {
          return false
        }
      })
    },
    async fnAddAiBoxDevice (obj) {
      console.log('新增边端盒子', obj)
      obj.category_id = this.addForm.scene_id[this.addForm.scene_id.length - 1]
      obj.scene_id = this.addForm.scene_id[0]
      const res = await addAiBoxDevice(obj)
      if (res && res.Code === 200) {
        this.closeAddDialogVisible(true)
      }
    },
    async fnUpdateAiBoxDevice (obj) {
      obj.category_id = this.addForm.scene_id[this.addForm.scene_id.length - 1]
      obj.scene_id = this.addForm.scene_id[0]
      obj.Id = this.Id
      console.log('obj', obj, this.addForm.scene_id)
      const res = await updateAiBoxDevice(obj)
      if (res && res.Code === 200) {
        this.closeAddDialogVisible(false)
      }
    },
    async fnGetSceneSelectList () {
      const res = await getBindScene()
      const rowList = this.filterChildren(res.Data)
      this.sceneSelectList = rowList
    },
    // 场景选择变化
    handleChange (value) {
      console.log('val', value)
      // this.changecategoryId = value[[value.length - 1]]
      // this.changesceneId = value[0]
      // this.addForm.category_id = this.changecategoryId
      // this.addForm.scene_id = this.changesceneId
    }
  }
}
</script>
<style scoped lang="less">

</style>
